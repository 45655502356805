import Vue from 'vue'
import VueRouter from 'vue-router'
const home = () => import('@/views/home')
const detail = () => import('@/views/detail')
const otherdetail = () => import('@/views/detail/otherIndex')
const search = () => import('@/views/search')
const order = () => import('@/views/order')

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: '',
	meta: {
		title: '',
		requireAuth: true,
	},
	component: home
}, {
	path: '/detail',
	name: 'detail',
	meta: {
		title: '商品详情',
		requireAuth: true,
	},
	component: detail
},, {
	path: '/otherdetail',
	name: 'otherdetail',
	meta: {
		title: '商品详情',
		requireAuth: true,
	},
	component: otherdetail
}, {
	path: '/reference',
	name: 'search',
	meta: {
		title: '订单查询',
		requireAuth: true,
	},
	component: search
}, {
	path: '/order',
	name: 'order',
	meta: {
		title: '订单列表',
		requireAuth: true,
	},
	component: order
}]

const router = new VueRouter({
	mode: 'history',
	// base: '/pays/',
	routes
})

export default router